/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

::-webkit-scrollbar-thumb {
  background: #363a43;
  border-radius: 10px;
  height: 100px;
}
